import { createSlice } from '@reduxjs/toolkit';
import { AUTH_STATUS, INITIAL_STATE } from 'common/auth/AUTH_CONSTANTS';
import ROUTES from 'constants/ROUTES';

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    clearAuth: () => INITIAL_STATE,
    setAuth: (state, action) => {
      const { meta = {}, ...payload } = action.payload;
      const { authStatus, route } = meta;
      let { isAccountModalRequired } = payload;

      if (authStatus === AUTH_STATUS.accountFoundWithAgent || authStatus === AUTH_STATUS.gettingAccount) {
        const {
          agent: { address1, agentCode, city, email, firstName, lastName, phone, agentRelationship, zip },
          msal,
        } = payload;
        const agentNumber = msal?.idTokenClaims?.agentNumber;
        const areAnyRequireFieldsEmpty =
          !address1 || !agentCode || !city || !email || !firstName || !lastName || !phone || !agentRelationship || !zip;

        const hasFirstTimeUserSeenModal = agentNumber ? isAccountModalRequired === false : true;
        isAccountModalRequired =
          (areAnyRequireFieldsEmpty || !hasFirstTimeUserSeenModal) &&
          route.key !== ROUTES.welcome.key &&
          !route.isPublic;
      }

      const newState = {
        ...state,
        ...payload,
        agency: { ...state.agency, ...payload.agency },
        agent: { ...state.agent, ...payload.agent },
        isAccountModalRequired,
        msal: { ...state.msal, ...payload.msal },
      };
      return newState;
    },
    setAuthAccountModalRequired: (state, action) => {
      return {
        ...state,
        isAccountModalRequired: action.payload,
      };
    },
  },
});

export const { clearAuth, setAuth, setAuthAccountModalRequired } = authSlice.actions;

export default authSlice;

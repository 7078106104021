import { IMAGE_FALLBACK, IMAGE_FALLBACK_CSS_CLASS, IMAGE_HANDLER_BASE_URL, IMAGE_NONE } from 'constants/IMAGE';
import { sanitize } from 'dompurify';
import { logError } from './logging';
import { BREAKPOINTS } from './responsive';

const brokenImageHandler = (img, fallbackImage) => (evt) => {
  const addErrorClass = () => {
    if (target.className.indexOf(IMAGE_FALLBACK_CSS_CLASS) < 0) {
      target.className += ` ${IMAGE_FALLBACK_CSS_CLASS}`;
    }
  };
  const removeErrorClass = () => {
    target.className = target.className.replace(IMAGE_FALLBACK_CSS_CLASS, '');
  };
  const { target } = evt;
  if (target.srcset) {
    target.srcset = '';
  }
  const errorSrc = IMAGE_FALLBACK;
  removeErrorClass();
  if (target.src.indexOf(IMAGE_HANDLER_BASE_URL) > -1) {
    target.src = img.errorSrc || errorSrc;
    addErrorClass();
  } else if ([img.errorSrc, target.src, fallbackImage].includes(IMAGE_NONE)) {
    target.src = errorSrc;
    target.style.visibility = 'hidden'; // Hide but keep the space
    target.onError = '';
  } else if (target.src !== IMAGE_FALLBACK) {
    target.src = errorSrc;
    addErrorClass();
  } else {
    target.style.display = 'none';
    target.onError = '';
  }
};

const getBestFitImage = (imgArray) => (aspectRatioType) => {
  const best = (imgArray || []).find((img) => img.type === aspectRatioType);
  if (best) {
    return best;
  }

  const getNumericRatio = (imgType) => {
    const [w, h] = imgType.split('x');
    return Number.parseFloat(w, 10) / Number.parseFloat(h, 10);
  };

  const desiredAr = getNumericRatio(aspectRatioType);
  const imageArray = [];
  (imgArray || []).forEach((img) => {
    const ar = getNumericRatio(img.type);
    imageArray.push({ ...img, aspectRatio: ar, diffRatio: Math.abs(desiredAr - ar) });
  });
  const nextBest = (imageArray || []).sort((a, b) => (a?.diffRatio < b?.diffRatio ? -1 : 1))?.[0];
  if (nextBest) {
    return nextBest;
  }
  return imgArray[0];
};

const getImageHandlerPropsByBreakpoint = ({
  aspectRatioToBreakpointMap = {},
  includeMissingBreakpoints = true,
  mediaSet = [],
}) => {
  const imageHandlerPropByByBreakpoint = (mediaSet || []).reduce((acc, image) => {
    const imageHandlerProps = getImageHandlerProps({ image });
    acc[aspectRatioToBreakpointMap[image.type]] = imageHandlerProps;
    return acc;
  }, {});

  if (includeMissingBreakpoints) {
    if (imageHandlerPropByByBreakpoint[BREAKPOINTS.xs] === undefined) {
      throw new Error(
        `imageUtils - getImageHandlerPropsByBreakpoint: 
        if includeMissingBreakpoints is true, then an xs breakpoint is required.`
      );
    }
    let lastImage = imageHandlerPropByByBreakpoint[BREAKPOINTS.xs];

    Object.values(BREAKPOINTS).forEach((breakpoint) => {
      imageHandlerPropByByBreakpoint[breakpoint] = imageHandlerPropByByBreakpoint[breakpoint] || lastImage;
      lastImage = imageHandlerPropByByBreakpoint[breakpoint];
    });
  }

  return imageHandlerPropByByBreakpoint;
};

const getImageHandlerProps = ({ image, fallbackImage }) => {
  try {
    const { alt, id, src, url, ...imageProps } = image || {};
    const imgSrc = url || src || '';
    const splitIndex = imgSrc.lastIndexOf('/');
    const bucket = imgSrc.substr(0, splitIndex);
    const imageKey = imgSrc.substr(splitIndex + 1);
    const errorSrc = fallbackImage || IMAGE_FALLBACK;
    const formattedAlt = sanitize(alt, { ALLOWED_TAGS: [] });
    return {
      ...imageProps,
      alt: formattedAlt,
      ariaLabel: formattedAlt,
      baseUrl: IMAGE_HANDLER_BASE_URL,
      brokenImageHandler: brokenImageHandler({ errorSrc }, fallbackImage || imgSrc),
      bucket,
      errorSrc,
      id: id || imageKey,
      imageKey,
      isResponsive: true,
      src: imgSrc?.toLowerCase()?.endsWith('.svg') ? imgSrc : '',
    };
  } catch (error) {
    logError('imageUtils - getImageHandlerProps', error);
    return undefined;
  }
};

const onImageErrorSvg = (event) => {
  const { target } = event;
  if (target.src !== IMAGE_FALLBACK) {
    target.src = IMAGE_FALLBACK;
    if (target.className.indexOf('error-img') < 0) {
      target.className += ' error-img';
    }
  }
};

export { getBestFitImage, getImageHandlerProps, getImageHandlerPropsByBreakpoint, IMAGE_FALLBACK, onImageErrorSvg };

const env = window?.location?.hostname?.includes('localhost') ? process.env : window.env;

const IS_LOCAL = env.REACT_APP_IS_LOCAL || '';

const B2C_API_URI = env.REACT_APP_B2C_API_URI || '';
const B2C_CLIENT_ID = env.REACT_APP_B2C_CLIENT_ID || '';
const B2C_DOMAIN = env.REACT_APP_B2C_DOMAIN || '';
const B2C_TENANT_ID = env.REACT_APP_B2C_TENANT_ID || '';

const ENVIRONMENT_CODE = env.REACT_APP_ENVIRONMENT_CODE || '';
const TAP_API_BASE_URL = env.REACT_APP_TAP_API_BASE_URL || '';
const MVJ_API_BASE_URL = env.REACT_APP_MVJ_API_BASE_URL || '';
const OLB_API_BASE_URL = env.REACT_APP_OLB_API_BASE_URL || '';

export {
  B2C_API_URI,
  B2C_CLIENT_ID,
  B2C_DOMAIN,
  B2C_TENANT_ID,
  ENVIRONMENT_CODE,
  IS_LOCAL,
  MVJ_API_BASE_URL,
  OLB_API_BASE_URL,
  TAP_API_BASE_URL,
};

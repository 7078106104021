const COOKIE_KEYS = Object.freeze({
  newAccount: { name: 'newAccount', expireDays: 400, value: true },
});

const ENVS = Object.freeze({
  dev: 'dev',
  dev1: 'dev1',
  dev2: 'dev2',
  dev3: 'dev3',
  int: 'int',
  prod: 'prod',
  qa: 'qa',
  qa1: 'qa1',
  qa2: 'qa2',
  qa3: 'qa3',
});

const STORAGE_KEYS = Object.freeze({
  appInsightsInfo: 'APP_INSIGHTS_INFO',
  authLoginAutoTries: 'AUTH_LOGIN_AUTO_TRIES',
  history: 'history',
  homePageModal: 'homePageModal',
});

export { COOKIE_KEYS, ENVS, STORAGE_KEYS };

import { BASE_PATH } from 'constants/API';
import { useNavigate as reactRouterDomUseNavigate } from 'react-router-dom';

const isLinkExternal = (url) => {
  return (
    url.startsWith('mailto:') ||
    url.startsWith('tel:') ||
    (/^https?:\/\//.test(url) && !url.startsWith(`${window.location.origin}${BASE_PATH}`))
  );
};

const openUrlInTabWithFallback = ({ callback, target, url }) => {
  const tab = window.open(url, target);
  if (tab === null) {
    document.location.href = url;
  }
  if (callback) {
    callback();
  }
};

const onNavigate = ({ callback, navigate, target = '_blank', url = '' }) => {
  if (isLinkExternal(url)) {
    openUrlInTabWithFallback({ callback, target, url });
  } else {
    let formattedUrl = url?.startsWith(window.location.origin) ? url.replace(window.location.origin, '') : url;
    formattedUrl = formattedUrl?.startsWith(BASE_PATH) ? formattedUrl : `${BASE_PATH}${formattedUrl}`;
    navigate(formattedUrl);
  }
};

const useNavigate = () => {
  const reactRouterDomNavigate = reactRouterDomUseNavigate();
  const navigate = (url, target, callback) => onNavigate({ callback, navigate: reactRouterDomNavigate, target, url });
  return navigate;
};

export default useNavigate;

export { isLinkExternal, onNavigate, openUrlInTabWithFallback };

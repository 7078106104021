const createDownloadLink = ({ data, title, type }) => {
  if (!data && !title && !type) {
    throw new Error('file.createDownloadLink required data, title, and type.');
  }
  const uri = type + data;
  const downloadLink = document.createElement('a');
  downloadLink.href = uri;
  downloadLink.download = title;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export { createDownloadLink };

import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights as appInsights } from '@microsoft/applicationinsights-web';
import { appInsightsHelper } from '@viking-eng/telemetry';
import { STORAGE_KEYS } from 'constants/CONFIG';
import { ENVIRONMENT_CODE } from 'constants/ENV';
import { APP_INSIGHTS, IS_ERROR_LOG_LEVEL_ENABLED, IS_INFO_LOG_LEVEL_ENABLED, LOGGING_LEVELS } from 'constants/LOGGING';
import { createBrowserHistory } from 'history';
import { nanoid } from 'nanoid';
import { consoleOutput, logError } from 'utils/logging';

try {
  if (navigator.cookieEnabled) {
    appInsightsHelper.initWeb({
      appInsights,
      appInsightsConfig: APP_INSIGHTS,
      environment: ENVIRONMENT_CODE,
      history: createBrowserHistory({}),
      isLogErrorEnabled: IS_ERROR_LOG_LEVEL_ENABLED,
      isLogInfoEnabled: IS_INFO_LOG_LEVEL_ENABLED,
      logError: (logData) => consoleOutput(LOGGING_LEVELS.ERROR, logData),
      logInfo: (logData) => consoleOutput(LOGGING_LEVELS.INFO, logData),
      reactPlugin: new ReactPlugin(),
      sessionId: nanoid(),
      sessionKey: STORAGE_KEYS.appInsightsInfo,
    });

    appInsightsHelper.updateCustomDimensions({ accountId: 'NOT_SET', tapSessionId: 'NOT_SET' });
  }
} catch (error) {
  logError('App Insights - initWeb', error);
}

const BREAKPOINTS = Object.freeze({
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
});

const BREAKPOINTS_MIN_VALUES = Object.freeze({
  [BREAKPOINTS.xs]: 0,
  [BREAKPOINTS.sm]: 576,
  [BREAKPOINTS.md]: 768,
  [BREAKPOINTS.lg]: 992,
  [BREAKPOINTS.xl]: 1200,
  [BREAKPOINTS.xxl]: 1400,
});

const BREAKPOINTS_MAX_VALUES = Object.freeze({
  [BREAKPOINTS.xs]: 576,
  [BREAKPOINTS.sm]: 768,
  [BREAKPOINTS.md]: 992,
  [BREAKPOINTS.lg]: 1200,
  [BREAKPOINTS.xl]: 1400,
});

const getBreakpointFromWidth = (width) => {
  let breakpoint = BREAKPOINTS.xs;
  if (width >= BREAKPOINTS_MIN_VALUES.xs && width < BREAKPOINTS_MIN_VALUES.sm) {
    breakpoint = BREAKPOINTS.xs;
  } else if (width >= BREAKPOINTS_MIN_VALUES.sm && width < BREAKPOINTS_MIN_VALUES.md) {
    breakpoint = BREAKPOINTS.sm;
  } else if (width >= BREAKPOINTS_MIN_VALUES.md && width < BREAKPOINTS_MIN_VALUES.lg) {
    breakpoint = BREAKPOINTS.md;
  } else if (width >= BREAKPOINTS_MIN_VALUES.lg && width < BREAKPOINTS_MIN_VALUES.xl) {
    breakpoint = BREAKPOINTS.lg;
  } else {
    breakpoint = BREAKPOINTS.xl;
  }
  return breakpoint;
};

export { BREAKPOINTS, BREAKPOINTS_MAX_VALUES, BREAKPOINTS_MIN_VALUES, getBreakpointFromWidth };

import { initializeTagData } from 'analytics/Analytics';
import { OFFICES } from 'constants/COUNTRIES';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { getRouteFromPathname } from 'utils/routing';

const AnalyticsSite = ({ children }) => {
  const route = getRouteFromPathname(window.location.pathname);

  useEffect(() => {
    initializeTagData(
      {
        country: OFFICES.US,
        isLoggedIn: false,
        pageId: route.id,
        pageName: route.title,
        path: window.location.pathname,
        queryParams: window.location.search,
      },
      OFFICES.US,
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

AnalyticsSite.propTypes = {
  children: PropTypes.node,
};

export default AnalyticsSite;

const fixMvjPageGroupCards = (cmsData, officeCode, voyageType, application) => {
  const { card } = cmsData;
  // expects shape { jcrName: 'x', card: { obj } }
  if (
    !card ||
    (officeCode && card.officeCode?.length && !card.officeCode.includes(officeCode)) ||
    (voyageType && !card.voyageType?.length && !card.voyageType.includes(voyageType)) ||
    (application && !card.application?.length && !card.application.includes(application))
  ) {
    return undefined; // this fixes the jcrName issue
  }
  return cmsData;
};

const cmsTypeFixMapping = {
  mvjPageGroupCards: fixMvjPageGroupCards,
};

const fixMockCMSData = (cmsData, officeCode, voyageType, application) => {
  const addProp = (obj, key, value) => {
    if (!JUNK_CMS.includes(key)) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = value;
    }
  };
  const JUNK_CMS = ['nodeType', 'path', 'application', 'officeCode', 'voyageType'];
  if (Array.isArray(cmsData)) {
    const ret = [];
    cmsData.forEach((elem) => {
      const fixed = fixMockCMSData(elem, officeCode, voyageType, application);
      if (fixed) {
        ret.push(fixed);
      }
    });
    return ret.filter((elem) => !!elem);
  }

  if (typeof cmsData === 'object') {
    if (officeCode && cmsData?.officeCode?.length && !cmsData.officeCode.includes(officeCode)) {
      return undefined;
    }
    if (voyageType && cmsData?.voyageType?.length && !cmsData.voyageType.includes(voyageType)) {
      return undefined;
    }
    if (cmsTypeFixMapping[cmsData?.nodeType]) {
      return cmsTypeFixMapping[cmsData.nodeType](cmsData, officeCode, voyageType, application);
    }
    const ret = {};
    Object.keys(cmsData).forEach((key) => {
      const prop = cmsData[key];
      if (prop) {
        if (typeof prop === 'object') {
          const fixed = fixMockCMSData(prop, officeCode, voyageType, application);
          if (fixed) {
            addProp(ret, key, fixed);
          }
        } else {
          addProp(ret, key, prop);
        }
      }
    });
    return ret;
  }
  return cmsData;
};

const getCommonData = ({ commonData, tokenData, propsData, shipCategories, stringsData, officeCode, voyageType }) => {
  return {
    cms: {
      common: fixMockCMSData(commonData, officeCode, voyageType),
      token: fixMockCMSData(tokenData, officeCode, voyageType),
    },
    props: { ...propsData, shipCategories: shipCategories.shipCategory },
    strings: stringsData,
  };
};

export { fixMockCMSData, getCommonData };

import { ANALYTICS_EVENT_TYPES, ANALYTICS_LINK_TYPES, triggerLinkEvent } from 'analytics/Analytics';
import { getCms } from 'app/services/tap/cms';
import { handleRedirectLinks } from 'common/link/redirectLinkHelper';
import { CMS_REDIRECT_URL_KEYS } from 'constants/API';
import { OFFICES } from 'constants/COUNTRIES';
import { TAP_API_BASE_URL } from 'constants/ENV';
import { onNavigate } from 'hooks/useNavigate';
import _camelCase from 'lodash.camelcase';
import _get from 'lodash.get';
import { BREAKPOINTS } from 'utils/responsive';
import { getImageHandlerProps } from './imageUtils';
import { logError } from './logging';
import { keyify } from './string';

const getCmsDataByCmsPaths = async ({ cmsPaths, dispatch, office = OFFICES.US }) => {
  const promises = cmsPaths.map((cmsPath) => dispatch(getCms.initiate({ cmsPath, office })));
  const responses = await Promise.allSettled(promises);
  const combinedData = responses.reduce((acc, val, index) => {
    acc[cmsPaths[index].key] = val.value?.data;
    return acc;
  }, {});
  return combinedData;
};

const getFormattedName = (name, transformName, isCamelCase) => {
  const formattedName = transformName ? transformName(name) : name;
  return isCamelCase ? _camelCase(formattedName) : formattedName;
};

const getItemByJcrName = ({ items, jcrName, key }) => {
  return key ? items?.find((item) => item.jcrName === jcrName)?.[key] : items?.find((item) => item.jcrName === jcrName);
};

const getItemLikeJcrName = ({ items, jcrName, key }) => {
  return key
    ? items?.find((item) => item.jcrName.includes(jcrName))?.[key]
    : items?.find((item) => item.jcrName.includes(jcrName));
};

const getItemsByJcrName = ({ isCamelCase = false, items, key, transformName }) =>
  items?.reduce((acc, item) => {
    acc[getFormattedName(item.jcrName, transformName, isCamelCase)] = key ? item[key] : item;
    return acc;
  }, {});

const getItemsLikeJcrName = ({ isCamelCase = false, items, jcrName, key, transformName }) =>
  items
    ?.filter((item) => item.jcrName.includes(jcrName))
    ?.reduce((acc, item) => {
      acc[getFormattedName(item.jcrName, transformName, isCamelCase)] = key ? item[key] : item;
      return acc;
    }, {});

const getPageCards = ({ data, navigate }) =>
  getFormattedCards({ cards: data?.groups?.find(({ jcrName }) => jcrName.includes('page-cards')), navigate });

const getCardPrimaryButton = ({ card, navigate, useRedirectClickValues }) => {
  return {
    onButtonClick: (e) => {
      triggerLinkEvent({
        event: ANALYTICS_EVENT_TYPES.BUTTON_CLICK,
        linkType: ANALYTICS_LINK_TYPES.BUTTON,
        cta_name: keyify(card?.callToActionTitle),
        linkId: keyify(card.jcrName),
      });
      if (!Object.values(CMS_REDIRECT_URL_KEYS).includes(card.callToActionUrl)) {
        return onNavigate({ navigate, url: card?.callToActionUrl?.replace('[API_PATH]', TAP_API_BASE_URL) });
      }
      e.currentTarget.classList.add('loading');
      handleRedirectLinks({
        isRedirectModalEnabled: false,
        redirectUrl: card?.callToActionUrl,
        useRedirectClickValues,
      });
      return null;
    },
    text: card?.callToActionTitle,
    callToActionUrl: card?.callToActionUrl,
  };
};

const getFormattedCard = ({ card, id, mediaSetIndex, navigate, useRedirectClickValues }) => {
  const image = getImageHandlerProps({
    image: { alt: card?.mediaSet?.[mediaSetIndex]?.alt, url: card?.mediaSet?.[mediaSetIndex]?.url },
  });
  const images = Object.values(BREAKPOINTS)
    .filter((breakpoint) => breakpoint !== 'xxl')
    .map((breakpoint) => ({ ...image, tier: breakpoint }));
  return {
    description: card?.description || card?.subtitle,
    id,
    images,
    primaryButton: getCardPrimaryButton({ card, navigate, useRedirectClickValues }),
    target: card.callToActionUrl?.replace('[API_PATH]', TAP_API_BASE_URL),
    title: card.title || card.subtitle,
  };
};

const getFormattedCards = ({ cards, mediaSetIndex = 0, navigate, useRedirectClickValues }) =>
  (cards || [])
    .filter((card) => !!card)
    .map((card, idx) => getFormattedCard({ card, id: `card-${idx}`, mediaSetIndex, navigate, useRedirectClickValues }));

/**
 * @param {object} data - Data object from CMS
 * @param {boolean} [isFetching=false] - Determines if data is still loading
 * @param {string} path - Path to CMS data
 * @return {string} CMS Value
 */
const getValue = ({ data, isFetching = false, path }) => {
  if (!isFetching) {
    if (typeof data !== 'object') {
      throw Error('utils/cms - getValue - data must be an object');
    }
    if (typeof path !== 'string') {
      throw Error('utils/cms - getValue - path must be a string');
    }
    const VALUE_NOT_FOUND = 'VALUE_NOT_FOUND';
    const value = _get(data, path, VALUE_NOT_FOUND);
    if (value === VALUE_NOT_FOUND) {
      logError('cms utils - getValue', { data, path });
    }
    return value;
  }
  return undefined;
};

/**
 * @param {object} data - Data object from CMS
 * @param {boolean} [isFetching=false] - Determines if data is still loading
 * @param {(string[]|object)} paths - Array of strings or object with key value pairs
 * @return {object} If path is Array of strings, each string will be the key, otherwise it will use the object keys.
 */
const getValues = ({ data, isFetching = false, paths }) => {
  if (!isFetching) {
    if (typeof data !== 'object' && !Array.isArray(data)) {
      throw Error('utils/cms - getValues - data must be an object');
    }
    const pathsError = 'utils/cms - getValues - paths must be an array of strings or an object with key value pairs.';
    if (Array.isArray(paths)) {
      if (paths?.length === 0 || typeof paths?.[0] !== 'string') {
        throw Error(pathsError);
      } else {
        return paths.reduce((acc, path) => {
          acc[path.key || path] = getValue(data, path.path || path);
          return acc;
        }, {});
      }
    } else {
      if (typeof paths === 'object') {
        return Object.entries(paths).reduce((acc, [key, path]) => {
          acc[key] = getValue({ data, path });
          return acc;
        }, {});
      }
      throw Error(pathsError);
    }
  }
  return {};
};

const isEmpty = (inputObj) =>
  !inputObj ||
  (Array.isArray(inputObj) && inputObj.length < 1) ||
  (typeof inputObj === 'object' && Object.keys(inputObj).length < 1);

export {
  getCmsDataByCmsPaths,
  getFormattedCards,
  getItemByJcrName,
  getItemLikeJcrName,
  getItemsByJcrName,
  getItemsLikeJcrName,
  getPageCards,
  getValue,
  getValues,
  isEmpty,
};

import { ANALYTICS_EVENT_TYPES, ANALYTICS_LINK_TYPES, triggerLinkEvent } from 'analytics/Analytics';
import { BASE_PATH, CMS_REDIRECT_URL_KEYS } from 'constants/API';
import ROUTES from 'constants/ROUTES';
import { onNavigate } from 'hooks/useNavigate';
import _sortBy from 'lodash.sortby';
import { getItemsLikeJcrName } from './cms';
import { keyify } from './string';

const baseHref = `/${document?.querySelector('base')?.getAttribute('href')?.split('/')[1]}` || '';

// Note: Route titles and description are mapped from ROUTES.js using the key name to the jcrName in cms.common.items.
// These are using in navigation and document.title.
// Because jcrName cannot be a duplicate, we append a number in CMS, but then remove when mapping.
// TODO: This code is a bit brittle, so we should revisit once we do the "real" CMS integration.
const transformRouteJcrName = (jcrName) => {
  let routeName;
  const nameSplit = jcrName.split('-');
  if (Number.isNaN(Number(nameSplit[nameSplit.length - 1]))) {
    routeName = jcrName;
  } else {
    nameSplit.pop();
    routeName = nameSplit.join('-');
  }
  return routeName;
};

const getAuthSortedRoutes = ({ commonData, getIsRouteAuthorized, navigate, orderKey, resetHistory }) => {
  const routeItems =
    getItemsLikeJcrName({
      items: commonData?.cms?.common?.items,
      jcrName: 'routes-',
      transformName: transformRouteJcrName,
    }) || [];

  const filteredRoutes = _sortBy(Object.values(ROUTES), orderKey)
    .filter((route) => {
      return (
        route[orderKey] !== undefined &&
        routeItems[`routes-${route.key}`] !== undefined &&
        getIsRouteAuthorized(route.auth)
      );
    })
    .reduce((acc, route) => {
      const { title, callToActionUrl } = getRouteCommonData({ route, commonData });
      acc.push({
        ...route,
        isSmallText: false,
        onClick: () => {
          triggerLinkEvent({
            event: ANALYTICS_EVENT_TYPES.MAIN_NAVIGATION,
            main_nav_cat: orderKey === 'headerLeftOrder' ? 'hamburger_menu' : 'account_menu',
            main_nav_sub_cat: keyify(title),
            linkText: keyify(title),
            linkType: ANALYTICS_LINK_TYPES.LINK,
            linkId: `auth-route-${keyify(title)}`,
          });
          if (!route.isExternal) {
            resetHistory();
          }
          if (!isRedirectUrl(callToActionUrl || route.url)) {
            onNavigate({ navigate, url: callToActionUrl || route.url });
          }
        },
        openInNewWindow: route.isExternal,
        text: title || route.title,
        title: title || route.title,
      });
      return acc;
    }, []);

  return filteredRoutes;
};

const getRouteCommonData = ({ route, commonData }) => {
  const routeData =
    commonData?.cms?.common?.items?.find(
      ({ jcrName }) =>
        transformRouteJcrName(jcrName) === `routes-${route.key}` ||
        transformRouteJcrName(jcrName) === `routes-${route.id}`
    ) || {};
  if (!routeData?.longText) {
    routeData.longText = route.pageTitle;
  }
  return routeData;
};

const getRouteFromLink = (link) => {
  link = link.replace(BASE_PATH, '');
  const routeFromPathName =
    link === '/' || link === ''
      ? ROUTES.home
      : Object.values(ROUTES).find((route) => {
          return route.url === link;
        });
  return routeFromPathName;
};

const getRouteFromPathname = (pathname) => {
  pathname = pathname.replace(BASE_PATH, '');
  const routeFromPathName =
    pathname === '/' || pathname === ''
      ? ROUTES.home
      : Object.values(ROUTES).find((route) => {
          return route.url === `/${pathname.replace(baseHref, '').split('/')[1] || ''}`;
        });
  return routeFromPathName || ROUTES.notFound;
};

const isLinkUrl = (url) => !url.startsWith('mailto:') && !url.startsWith('tel:');

const isRedirectUrl = (url) => Object.values(CMS_REDIRECT_URL_KEYS).includes(url);

export { getAuthSortedRoutes, getRouteCommonData, getRouteFromLink, getRouteFromPathname, isLinkUrl, isRedirectUrl };

import { SORT_DIRECTION } from './ARRAY';
import ROUTES from './ROUTES';

const DEFAULT_SORT = [
  { directions: [SORT_DIRECTION.desc], name: 'bookingNo', properties: ['bookingNo'] },
  {
    directions: [SORT_DIRECTION.desc, SORT_DIRECTION.desc],
    name: 'lastName',
    properties: ['priceDetails[0].passengers[0].lastName', 'priceDetails[0].passengers[1].lastName'],
  },
  { directions: [SORT_DIRECTION.desc], name: 'voyageName', properties: ['voyageName'] },
  { directions: [SORT_DIRECTION.desc], name: 'embarkDate', properties: ['embarkDate'] },
  {
    directions: [SORT_DIRECTION.desc, SORT_DIRECTION.desc, SORT_DIRECTION.asc],
    name: 'deposit',
    properties: ['priceDetails[0].isNotOption', 'priceDetails[0].depDueDate', 'priceDetails[0].depositDue'],
  },
  {
    directions: [SORT_DIRECTION.desc, SORT_DIRECTION.desc, SORT_DIRECTION.asc],
    name: 'balance',
    properties: ['priceDetails[0].hasBalanceAmt', 'priceDetails[0].balDueDate', 'priceDetails[0].balanceAmt'],
  },
  {
    directions: [SORT_DIRECTION.desc],
    name: 'commission',
    properties: ['priceDetails[0].commission'],
  },
];

const ALL_SORT = [
  {
    directions: [SORT_DIRECTION.asc, SORT_DIRECTION.desc],
    name: 'statusBookingNo',
    properties: ['priceDetails[0].bookingStatusSortOrder', 'bookingNo'],
  },
  ...DEFAULT_SORT.slice(1),
];

const GIF_COMPLETED_YES = 'Y';

const ALL_YEARS = 'All Years';

const ALL_YEARS_OPTION = [{ year: 1, months: [1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12] }];

const BOOKING_STATUS = Object.freeze({
  ALL: { name: 'All', code: 'all', key: 'all', urlPath: 'all', isActive: true },
  CNF: { name: 'Confirmed', code: 'CNF', key: 'confirmed', urlPath: 'confirmed', isActive: true },
  CXL: { name: 'Canceled', code: 'CXL', key: 'canceled', urlPath: 'canceled', isActive: false },
  FNL: { name: 'Confirmed', code: 'FNL', key: 'confirmed', urlPath: 'confirmed', isActive: true },
  OFR: { name: 'Option', code: 'OFR', key: 'options', urlPath: 'options', isActive: true },
  PST: { name: 'Past', code: 'PST', key: 'past', urlPath: 'past', isActive: false },
  WL: { name: 'Wait List', code: 'WL', key: 'waitList', urlPath: 'waitList', isActive: false },
});

const activeStatuses = Object.values(BOOKING_STATUS).filter((status) => status.isActive);

const BOOKING_FILTER = Object.freeze({
  active: {
    display: 'Active',
    isBalanceDue: false,
    isInFilterSelect: true,
    isPersonal: false,
    key: 'active',
    sortIndex: 1,
    statuses: activeStatuses,
    url: `${ROUTES.bookings.url}/active`,
  },
  all: {
    display: 'All',
    isBalanceDue: false,
    isInFilterSelect: true,
    isPersonal: false,
    key: 'all',
    sortIndex: 0,
    statuses: Object.values(BOOKING_STATUS),
    url: `${ROUTES.bookings.url}/${BOOKING_STATUS.ALL.urlPath}`,
  },
  'balance-due': {
    display: 'Balance Due',
    isBalanceDue: true,
    isInFilterSelect: false,
    isPersonal: false,
    key: 'balance-due',
    statuses: activeStatuses,
    url: `${ROUTES.bookings.url}/balance-due`,
  },
  canceled: {
    display: 'Canceled',
    isBalanceDue: false,
    isInFilterSelect: true,
    isPersonal: false,
    key: 'canceled',
    sortIndex: 4,
    statuses: [BOOKING_STATUS.CXL],
    url: `${ROUTES.bookings.url}/${BOOKING_STATUS.CXL.urlPath}`,
  },
  confirmed: {
    display: 'Confirmed',
    isBalanceDue: false,
    isInFilterSelect: true,
    isPersonal: false,
    key: 'confirmed',
    sortIndex: 2,
    statuses: [BOOKING_STATUS.CNF, BOOKING_STATUS.FNL],
    url: `${ROUTES.bookings.url}/${BOOKING_STATUS.CNF.urlPath}`,
  },
  options: {
    display: 'Option',
    isBalanceDue: false,
    isInFilterSelect: true,
    isPersonal: false,
    key: 'options',
    sortIndex: 3,
    statuses: [BOOKING_STATUS.OFR],
    url: `${ROUTES.bookings.url}/${BOOKING_STATUS.OFR.urlPath}`,
  },
  past: {
    display: 'Past',
    isBalanceDue: false,
    isInFilterSelect: true,
    isPersonal: false,
    key: 'past',
    sortIndex: 5,
    statuses: [BOOKING_STATUS.PST],
    url: `${ROUTES.bookings.url}/${BOOKING_STATUS.PST.urlPath}`,
  },
  personal: {
    display: 'Personal',
    isBalanceDue: false,
    isInFilterSelect: false,
    isPersonal: true,
    key: 'personal',
    statuses: activeStatuses,
  },
  waitList: {
    display: 'Wait List',
    isBalanceDue: false,
    isInFilterSelect: false,
    isPersonal: false,
    key: 'waitList',
    statuses: [BOOKING_STATUS.WL],
  },
});

export { ALL_SORT, ALL_YEARS, ALL_YEARS_OPTION, BOOKING_FILTER, BOOKING_STATUS, DEFAULT_SORT, GIF_COMPLETED_YES };

import { combineReducers, configureStore, isRejected, isRejectedWithValue } from '@reduxjs/toolkit';
import { UNHANDLED_ENDPOINTS } from 'constants/API';
import { useDispatch } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'reduxjs-toolkit-persist';
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import { logError } from 'utils/logging';
import tapApi from './services/tap/api';
import authSlice from './slices/auth/authSlice';
import bookingsSlice from './slices/bookings/bookingsSlice';
import itinerarySlice from './slices/itinerary/itinerarySlice';
import redirectSlice from './slices/redirect/redirectSlice';

const rtkQueryErrorLogger = () => (next) => (action) => {
  const rejectedWithValue = isRejectedWithValue(action);
  const rejected = isRejected(action);
  if ((rejectedWithValue || rejected) && action?.error?.name !== 'ConditionError') {
    if (!UNHANDLED_ENDPOINTS.includes(action?.meta?.arg?.endpointName)) {
      logError('Store Error', { action });
    }
  }
  return next(action);
};
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel1,
  blacklist: [tapApi.reducerPath],
};

const reducers = combineReducers({
  [tapApi.reducerPath]: tapApi.reducer,
  agentBookings: bookingsSlice.reducer,
  auth: authSlice.reducer,
  redirectToPage: redirectSlice.reducer,
  selectedItinerary: itinerarySlice.reducer,
});

const persistedReducerVar = persistReducer(persistConfig, reducers);

const createStore = () => {
  const store = configureStore({
    reducer: persistedReducerVar,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat([rtkQueryErrorLogger, tapApi.middleware]),
  });
  const persistor = persistStore(store);
  return { persistor, store };
};

const useAppDispatch = useDispatch;

export { createStore, useAppDispatch };
